.profile{
    padding-top: 130px;
    &>div{
        max-width: 1178px;
        width: 100%;
        margin: 0 auto;
        @media (max-width: 767px) {
            width: calc(100% - 40px);
        }

    }
    .accountInfo{
        display: flex;
        justify-content: space-between;
        border: 1px solid #5EC800;
        backdrop-filter: blur(17px);
        background: rgba(0, 0, 0, 0.8);
        padding: 30px 60px;
        border-top-left-radius: 80px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 80px;
        position: relative;
        @media (max-width: 767px) {
            padding: 60px 20px 30px;
        }
        .follow{
            width: 120px;
        }
        .name{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
        .username{
            font-weight: 700;
            font-size: 30px;
            color: #fff;
            display: inline-block;
            margin-right: 30px;
            max-width: calc(100% - 210px);
            @media (max-width: 767px) {
                max-width: 100%;
            }
    
        }
        .info{
            display: flex;
            justify-content: flex-start;
            font-weight: 700;
            font-size: 16px;
            color: #fff;
            margin: 20px 0;
            gap: 24px;
            @media (max-width: 767px) {
                flex-direction: column;
                gap: 10px;
            }
    
            &>div{
                padding-right: 24px;
                border-right: 1px solid #677D54;
                @media (max-width: 767px) {
                    border-right: none;
                }
                &:last-of-type{
                    border-right: none;
                }
            }
            span{
                color: #5EC800;  
            }
        }
        .bio{
            font-weight: 400;
            font-size: 15px;
            color: #B2D296;
        }
        .edit{
            position: absolute;
            top: 0;
            right: 0;
            background: rgba(72, 111, 38, 0.35);
            border-radius: 0px 4px 0px 20px;
            .btn{
                background: none;
                border: none;
                border-radius: 0;
                padding: 0 30px;
                margin: 12px 0;
                color: #5EC800;
                svg{
                    margin-right: 7px;
                }
                &:first-of-type{
                    border-right: 1px solid #677D54;
                }
            }
        }
        &>div{
            &:first-of-type{
                display: grid;
                grid-template-columns: 142px auto;
                justify-content: flex-start;
                gap: 60px;
                width: 100%;
                @media (max-width: 767px) {
                    grid-template-columns: 70px auto;
                    gap: 20px;
                }
            }
            &>div{
                &:last-of-type{
                    width: 100%;
                    word-break: break-word;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    &>div{
                        width: 100%;
                    }
                }
            }
            .address{
                background: rgba(72, 111, 38, 0.35);
                border-radius: 20px;
                color: #5EC800;
                padding: 6px 10px;
                display: inline-flex;
                justify-content: center;
                gap: 10px;
                align-items: center;
                cursor: pointer;
                width: auto;
                svg{
                    height: 14px!important;
                    display: block;
                    fill: #5EC800;
                }
            }
        }
        .avatar{
            height: 142px;
            width: 142px;
            border-radius: 50%;
            border: 1px solid #5EC800;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 767px) {
                height: 70px;
                width: 70px;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
            svg{
                width: 50px;
            }
        }
    }
    .content{
        background: rgba(0, 0, 0, 0.6);
        border-top: 1px solid #5EC800;
        backdrop-filter: blur(17px);
        margin-top: 18px;
        padding: 20px 50px 50px;
        @media (max-width: 767px) {
            padding: 20px 20px 50px;
        }
        .select{
            width: 240px;
            .css-1s2u09g-control,.css-1pahdxg-control{
                border: 1px solid #677D54;
                border-radius: 4px;
                background: none;
                .css-6j8wv5-Input,.css-qc6sy-singleValue,.css-tlfecz-indicatorContainer{
                    color: #5EC800;
                }
                .css-1okebmr-indicatorSeparator{
                    display: none;
                }
            }
        }
        .list{
            margin-top: 20px;
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(auto-fit, minmax(207px, 1fr));
            align-items: flex-start;
            .item{
                background: #141815;
                border: 1px solid #677D54;
                border-radius: 4px;
                font-size: 14px;
                cursor: pointer;
                &>div{
                    &:last-of-type{
                        padding: 10px;
                        &>div{
                            &:first-of-type{
                                color: #B2D296;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                            &:last-of-type{
                                font-weight: 700;
                                color: #fff;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                            .price{
                                display: flex;
                                justify-content: space-between;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                &>div{
                                    &:first-of-type{
                                        color: #B2D296;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                    }
                                    &:last-of-type{
                                        font-weight: 700;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
                p{
                    color: #fff;
                }
                .img{
                    position: relative;
                    height: 207px;
                    width: 100%;
                    .tag{
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 27px;
                        height: 27px;
                        border-left: 1px solid #677D54;
                        border-bottom: 1px solid #677D54;
                        border-radius: 0px 4px 0px 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #171E36;
                    }
                    img{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                    .empty{
                        width: 100%;
                        height: 100%;
                        background: #0B0B0B;
                        border: 4px solid #1D231E;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg{
                            width: 52px;
                        }
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
@primary-color: #000;@border-radius-base: 20px;