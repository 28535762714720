.collection {
    width: 100%;
    height: 100%;
    padding-top: 130px;
    @media (max-width: 767px) {
        padding-top: 100px;
    }
    .banner{
        width: 100%;
        height: 100%;
        background-repeat: no-repeat!important;
        background-size: cover!important;
    }
    .info{
        max-width: 1178px;
        height: 356px;
        width: 100%;
        margin: 0 auto; 
        padding: 0 30px 30px;
        // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 76.76%);
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 30px;
        @media (max-width: 767px) {
            gap: 10px;
            align-items: flex-start;
            height: auto!important;
            padding: 0 15px 15px;
        }
        .avatar{
            height: 142px;
            width: 142px;
            border-radius: 50%;
            border: 1px solid #5EC800;
            overflow: hidden;
            @media (max-width: 767px) {
                width: 80px;
                height: 80px;
            }
            img{
                height: 100%;
                width: 100%;
                border-radius: 50%;
                border: 1px solid #5EC800;
            }
        }
        &>div{
            &:first-of-type{
                @media (max-width: 767px) {
                    display: none;
                }
            }
            &:last-of-type{
                width: calc(100% - 172px);
                padding-bottom: 20px;
                @media (max-width: 767px) {
                    width: 100%;
                }
                .name{
                    font-weight: 700;
                    font-size: 30px;
                    color: #fff;
                    max-width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
            .empty{
                width: 100%;
                height: 100%;
                background: #0B0B0B;
                border: 4px solid #1D231E;
                display: flex;
                justify-content: center;
                align-items: center;
                svg{
                    width: 40px;
                }
            }
            .time{
                width: auto;
            }
            .bio{
                color: #B2D296;
                padding: 10px 0;
            }
            .info1{
                display: flex;
                align-items: center;
                color: #fff;
                gap: 20px;
                .avatar{
                    display: none;
                    @media (max-width: 767px) {
                        display: block;
                    }
                }
                @media (max-width: 767px) {
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 10px;
                }
                span{
                    font-weight: 400;
                    font-size: 16px;
                    color: #5EC800;
                }
            }
            .info2{
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                gap: 20px;
                color: #fff;
                @media (max-width: 767px) {
                    grid-template-columns: repeat(3, 1fr);
                }
                span{
                    color: #B2D296;
                    display: block;
                }
            }
        }
    }
    .content{
        min-height: 100%;
        background: rgba(0, 0, 0, 0.6);
        border-top: 1px solid #5EC800;
        backdrop-filter: blur(17px);
        padding: 16px 0;
        .search-group{
            border-bottom: 1px solid #677D54;
            width: 450px;
            @media (max-width: 767px) {
                width: 100%;
            }
            button{
              background: none;
              border: none;
            }
            input{
              background: none;
              border: none;
              color: #fff;
              &:hover,&:focus{
                box-shadow: none;
              }
            }
          }
    }
    .list{
        margin-top: 30px;
        margin-bottom: 50px;
        display: flex;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        .item{
            background: #141815;
            border: 1px solid #677D54;
            border-radius: 4px;
            font-size: 14px;
            cursor: pointer;
            width: 207px;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
            @media (max-width: 767px) {
                width: 100%;
            }
            &>div{
                &:last-of-type{
                    padding:5px 10px;
                }
            }
            p{
                color: #fff;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-bottom: 0;
            }
            .img{
                position: relative;
                height: 207px;
                width: 100%;
                .tag{
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 27px;
                    height: 27px;
                    border-left: 1px solid #677D54;
                    border-bottom: 1px solid #677D54;
                    border-radius: 0px 4px 0px 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #171E36;
                }
                .empty{
                    width: 100%;
                    height: 100%;
                    background: #0B0B0B;
                    border: 4px solid #1D231E;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg{
                        width: 52px;
                    }
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .price{
                display: flex;
                justify-content: space-between;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                &>div{
                    &:first-of-type{
                        color: #B2D296;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    &:last-of-type{
                        font-weight: 700;
                        color: #fff;
                    }
                }
            }
        }
    }
}

@primary-color: #000;@border-radius-base: 20px;