a {
  color: #5EC800;;
  &:hover {
    color: #5EC800;;
    text-decoration: underline;
  }
}
.App {
  text-align: center;
}
html{
  background: #000;
}
body{
  background: linear-gradient(180deg, #000000 0%, #1C2713 100%); 
  background-size: 100%;
  min-height: 100vh;
}
.btn-primary{
  background: #5EC800;
  border-radius: 8px;
  border: none;
  color: #000;
  &:hover,&:focus{
    background: #5EC800;
  }
}
.loader{
  position: relative;
  height: 100px;
  .spinner {
      animation: rotate 2s linear infinite;
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -25px 0 0 -25px;
      width: 50px;
      height: 50px;
      
      & .path {
        stroke: hsl(210, 70, 75);
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
      }
      
    }
    
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
    
    @keyframes dash {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    }
    
}
.tooltip{
  --bs-tooltip-bg: rgba(94, 200, 0, 0.5);
}
@primary-color: #000;@border-radius-base: 20px;