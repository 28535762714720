.editProfileModal{
    .modal-header{
        border-bottom: none;
        padding: 30px 50px;
    }
    .modal-title{
        width: 100%;
        text-align: center;
        font-weight: 400;
        font-size: 20px;
        color: #5EC800;
    }
    .modal-body{
        padding: 0;
    }
    .modal-content{
        padding: 0 50px;
        background: rgba(0, 0, 0, 0.8);
        border: 1px solid #5EC800;
        backdrop-filter: blur(22px);
        border-radius: 80px 8px;
        .mb-3{
            &:first-of-type{
                display: flex;
                justify-content: center;
            }
        }
        label{
            color: #677D54;
        }
        input{
            background: #000;
            border: 1px solid #5EC800;
            border-radius: 4px;
            color: #5EC800;
        }
        .form-control[type=file]{
            display: none;
        }
        .uploadBtn{
            width: 142px;
            height: 142px;
            border-radius: 50%;
            border: 2px solid #5EC800;
            overflow: hidden;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .input-group{
            input{
                border-right: none;
            }
        }
        .input-group-text{
            background: none;
            border: 1px solid #5EC800;
            border-left: none;
            svg{
                cursor: pointer;
            }
        }
    }
    .modal-footer{
        padding: 30px 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 100px;
        border-top: none;
        .btn-secondary{
            background: none;
            border: 1px solid #677D54;
            color: #677D54;
        }
        .btn{
            width: 100%;
            margin: 0;
            &:disabled{
                background: #5EC800;
            }
            svg{
                width: 16px;
                height: 16px;
            }
        }
    }
}
@primary-color: #000;@border-radius-base: 20px;