.collectionList{
    padding-top: 150px;
    padding-bottom: 45px;
    .content{
        .title{
            font-weight: 700;
            font-size: 30px;
            color: #fff;
        }
        .select{
            width: 240px;
            .css-1s2u09g-control,.css-1pahdxg-control{
                border: 1px solid #677D54;
                border-radius: 4px;
                background: none;
                .css-6j8wv5-Input,.css-qc6sy-singleValue,.css-tlfecz-indicatorContainer{
                    color: #5EC800;
                }
                .css-1okebmr-indicatorSeparator{
                    display: none;
                }
            }
        }
        .list{
            margin-top: 20px;
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            align-items: flex-start;
            .item{
                background: #141815;
                border: 1px solid #677D54;
                border-radius: 4px;
                font-size: 14px;
                cursor: pointer;
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
                &>div{
                    &:last-of-type{
                        padding: 20px 24px;
                    }
                }
                p{
                    color: #fff;
                }
                .img{
                    position: relative;
                    height: 147px;
                    width: 100%;
                    .tag{
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 27px;
                        height: 27px;
                        border-left: 1px solid #677D54;
                        border-bottom: 1px solid #677D54;
                        border-radius: 0px 4px 0px 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #171E36;
                    }
                    .empty{
                        width: 100%;
                        height: 100%;
                        background: #0B0B0B;
                        border: 4px solid #1D231E;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg{
                            width: 52px;
                        }
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .info{
                    display: flex;
                    justify-content: flex-start;
                    gap: 18px;
                    &>div{
                        // &:first-of-type{
                        //     border: 1px solid #5EC800;
                        //     border-radius: 50%;
                        //     width: 60px;
                        //     height: 60px;
                        //     display: flex;
                        //     align-items: center;
                        //     justify-content: center;
                        //     svg{
                        //         width: 20px;
                        //     }
                        // }
                        // &:last-of-type{
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            &>div{
                                &:first-of-type{
                                    font-weight: 700;
                                    font-size: 16px;
                                    color: #fff;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }
                                &:last-of-type{
                                    font-weight: 400;
                                    font-size: 14px;
                                    color: #B2D296;
                                }
                            }
                        // }
                    }
                }
            }
        }
    }
}
@primary-color: #000;@border-radius-base: 20px;