.profile{
    background: url('../../assets/img/bg.png') no-repeat, linear-gradient(180deg, #000000 0%, #1C2713 100%); 
    padding-top: 150px;
    height: 100%;
    &>div{
        max-width: 1178px;
        width: 100%;
        margin: 0 auto;
    }
    .accountInfo{
        display: flex;
        justify-content: space-between;
        border: 1px solid #5EC800;
        backdrop-filter: blur(17px);
        background: rgba(0, 0, 0, 0.8);
        padding: 30px 60px;
        border-top-left-radius: 80px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 80px;
        position: relative;
        .edit{
            position: absolute;
            top: 0;
            right: 0;
        }
        &>div{
            &:first-of-type{
                display: flex;
                justify-content: space-between;
                gap: 60px;
            }
            .address{
                background: rgba(72, 111, 38, 0.35);
                border-radius: 20px;
                color: #5EC800;
                padding: 6px 10px;
                display: flex;
                justify-content: center;
                gap: 10px;
                align-items: center;
                cursor: pointer;
                svg{
                    height: 14px!important;
                    display: block;
                    fill: #5EC800;
                }
            }
        }
        .avator{
            height: 142px;
            width: 142px;
            border-radius: 50%;
            border: 1px solid #5EC800;
        }
    }
    .content{
        background: rgba(0, 0, 0, 0.6);
        border-top: 1px solid #5EC800;
        backdrop-filter: blur(17px);
        margin-top: 18px;
        padding: 20px 50px;
        .select{
            width: 240px;
            .css-1s2u09g-control,.css-1pahdxg-control{
                border: 1px solid #677D54;
                border-radius: 4px;
                background: none;
                .css-6j8wv5-Input,.css-qc6sy-singleValue,.css-tlfecz-indicatorContainer{
                    color: #5EC800;
                }
                .css-1okebmr-indicatorSeparator{
                    display: none;
                }
            }
        }
        .list{
            margin-top: 20px;
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(auto-fit, minmax(207px, 1fr));
            align-items: flex-start;
            .item{
                background: #141815;
                border: 1px solid #677D54;
                border-radius: 4px;
                font-size: 14px;
                &>div{
                    &:last-of-type{
                        padding: 0 10px;
                    }
                }
                p{
                    color: #fff;
                }
                .img{
                    position: relative;
                    .tag{
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 27px;
                        height: 27px;
                        border-left: 1px solid #677D54;
                        border-bottom: 1px solid #677D54;
                        border-radius: 0px 4px 0px 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #171E36;
                    }
                }
                .price{
                    display: flex;
                    justify-content: space-between;
                    &>div{
                        &:first-of-type{
                            color: #B2D296;
                        }
                        &:last-of-type{
                            font-weight: 700;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
@primary-color: #000;@border-radius-base: 20px;