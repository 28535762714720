.navbar-expand-sm .navbar-collapse{
  @media (min-width: 576px) {
    justify-content: space-between;
  }
}
.header{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 48.96%, rgba(0, 0, 0, 0) 100%);
  height: 100px;
  padding: 0!important;
  @media (max-width: 767px) {
    height: auto;
    padding: 10px 0!important;
    background: #000;
  }
  &.scroll{
    background: #000;
  }
  .nav-right{
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
    @media (max-width: 767px) {
      padding: 10px;
    }
    a{
      color: #fff;
      text-decoration: none;
    }
  }
  .wallet-icon{
    background: none;
    border: none;
  }
  .Burger {
    display: none;
    grid-area: burger;
    margin: 0;
    padding: 0;
    justify-self: self-start;
    font-size: 25px;
    border: none!important;
    background: none!important;
    outline: none;
    transition: 0.1s;
    @media (max-width: 767px) {
      display: inline;
    }
    svg{
      width: 35px;
      height: 35px;
    }
  }
  .search-group{
    border-bottom: 1px solid #677D54;
    width: 450px;
    position: relative;
    @media (max-width: 767px) {
      width: 100%;
    }
    .dropdown{
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(17px);
      border-radius: 8px;
      padding: 20px 0;
      .head{
        display: flex;
        justify-content: space-between;
        color: #677D54;
        font-size: 12px;
        border-bottom: 1px solid #677D54;
        margin: 0 20px;
        padding: 5px 0;
      }
      .empty{
        width: 30px;
        height: 30px;
        background: #0B0B0B;
        border: 1px solid #1D231E;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            width: 14px;
        }
      }
      .collections{
        color: #fff;
        font-size: 14px;
        .empty{
          border-radius: 4px;
        }
        &>div{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 4px 0;
          padding: 4px 20px;
          cursor: pointer;
          &:hover{
            background: rgba(72, 111, 38, 0.35);
          }
          &>div{
            &:first-of-type{
              display: flex;
              align-items: center;
              gap: 12px
            }
            &:last-of-type{
              color: #B2D296;
            }
          }
        }
        img{
          width: 30px;
          height: 30px;
          border-radius: 4px;
        }
      }
      .accounts{
        color: #fff;
        font-size: 14px;
        .empty{
          border-radius: 50%;
          margin-right: 12px;
        }
        &>div{
          width: calc(50% - 8px);
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          margin: 4px 0;
          padding: 4px 20px;
          cursor: pointer;
          &:hover{
            background: rgba(72, 111, 38, 0.35);
          }
          &:nth-of-type(odd){
            margin-right: 8px;

          }
          &:nth-of-type(even){
            margin-left: 8px;   
          }
        }
        img{
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 12px;
        }
      }
    }
    button{
      background: none;
      border: none;
      &:last-of-type{
        color: #B2D296;
      }
    }
    input{
      background: none;
      border: none;
      color: #fff;
      &:hover,&:focus{
        box-shadow: none;
      }
    }
  }
}
@primary-color: #000;@border-radius-base: 20px;