.searchDetail {
    min-height: 100%;
    width: 100%;
    padding-top: 130px;
    padding-bottom: 45px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    @media (max-width: 767px) {
        padding-top: 100px;
        grid-template-columns: repeat(1, 1fr);
    }
    &>div{
        overflow: hidden;
    }
    .left-content{
        .img{
            background: #000;
            width: 100%;
            border-radius: 4px;
            border: 1px solid #677D54;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 500px;
            @media (max-width: 767px) {
                height: 300px;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .properties{
            background: #000;
            border: 1px solid #677D54;
            padding: 16px;
            border-radius: 4px;
            margin-top: 20px;
            &>div{
                &:first-of-type{
                    font-weight: 400;
                    font-size: 14px;
                    color: #B2D296;
                    display: flex;
                    justify-content: space-between;
                    svg{
                        cursor: pointer;
                    }
                }
                &:last-of-type{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 8px;
                    margin-top: 8px;
                    &>div{
                        background: #1E201D;
                        border-radius: 4px;
                        padding: 10px 12px;

                        &>div{
                            &:first-of-type{
                                font-weight: 400;
                                font-size: 14px;
                                color: #B2D296;
                            }
                            &:last-of-type{
                                font-weight: 700;
                                font-size: 14px;
                                color: #fff;
                                display: flex;
                                justify-content: space-between;
                                gap: 20px;
                            }
                        }
                    }

                }
            }
        }
        .collection-info{
            background: #000;
            border: 1px solid #677D54;
            padding: 16px 16px 20px;
            border-radius: 4px;
            margin-top: 20px;
            &>div{
                &:first-of-type{
                    font-weight: 400;
                    font-size: 14px;
                    color: #B2D296;
                }
                &:last-of-type{
                    display: grid;
                    grid-template-columns: 122px auto;
                    gap: 20px;
                    margin-top: 12px;
                    cursor: pointer;
                    &>div{
                        &:last-of-type{
                            &>div{
                                &:first-of-type{
                                    font-weight: 700;   
                                    font-size: 24px;
                                    color: #fff;
                                }
                                &:last-of-type{
                                    font-weight: 400;   
                                    font-size: 14px;
                                    color: #fff;
                                    word-break: break-word;
                                }
                            }
                        }
                    }
                }
            }
            .avatar{
                width: 122px;
                height: 122px;
                border-radius: 4px;
                border: 1px solid #677D54;
                display: flex;
                justify-content: center;
                align-items: center;
                svg{
                    width: 30px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .right-content{
        &>div{
            border: 1px solid #677D54;
            border-radius: 4px;
            background: #000;
            padding: 14px 35px;
            @media (max-width: 767px) {
                padding: 14px 15px;
            }
            &:not(:last-of-type){
                border-bottom: 1px solid #677D54;
            }
            &:nth-of-type(2),&:nth-of-type(3){
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
            &:nth-of-type(1),&:nth-of-type(2){
                display: grid;
                border-bottom: none;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                &>div{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    &>div{
                        &:first-of-type{
                            font-weight: 400;
                            font-size: 14px;
                            color: #B2D296;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        &:last-of-type{
                            font-weight: 700;
                            font-size: 20px;
                            color: #fff;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
        .name{
            font-weight: 700!important;
            font-size: 30px!important;
            color: #fff!important;
        }
        .id{
            font-weight: 400!important;
            font-size: 14px!important;
            color: #B2D296!important;
        }
        .address{
            font-weight: 400!important;
            font-size: 20px!important;
            text-decoration-line: underline;
            color: #5EC800!important;
            cursor: pointer;
        }
        .storage{
            &>div{
                &:first-of-type{
                    font-weight: 400;
                    font-size: 14px;
                    color: #B2D296;
                }
            }
            .input-group{
                margin: 14px 0;
                .input-group-text{
                    background: #131512;
                    border: 1px solid #677D54;
                    border-radius: 80px 0px 0px 80px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #fff;
                    width: 135px;
                    justify-content: center;
                }
                .form-control{
                    background: #1E201D;
                    border: 1px solid #677D54;
                    border-radius: 0 80px 80px 0;
                    color: #fff;
                }
            }
            .saoStorage{
                background: #141614;
                border-radius: 20px;
                .input-group-text{
                    background: #5EC800;
                    border: 1px solid #5EC800;
                    border-radius: 80px 0px 0px 80px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #fff;
                }
                .form-control{
                    background: #1E201D;
                    border: 1px solid #5EC800;
                    border-radius: 0 80px 80px 0;
                    color: #5EC800;
                }
                .storageInfo{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    padding-bottom: 20px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    &>div{
                        padding: 0 27px;
                        &:first-of-type{
                            border-right: 1px solid #677D54;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        &>div{
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            &:first-of-type{
                                font-weight: 400;
                                font-size: 14px;
                                color: #B2D296;
                            }
                            &:last-of-type{
                                font-weight: 400;
                                font-size: 20px;
                                color: #5EC800;
                                text-decoration-line: underline;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }
        }
    }
}

@primary-color: #000;@border-radius-base: 20px;